/* jquery */
window.$ = window.jQuery = require('jquery');

/* bootstrap */
require('popper.js');
require('bootstrap');

// for supporting Object.keys not compatible in IE
require('@babel/polyfill');

/* sprintf (POedit) */
require('sprintf-js');

// Service Worker
// require('../sw/core/register-sw');

/* GSAP FOR ANIMATION */
// import {TweenMax, Power2, TimelineLite, TimelineMax, CSSRulePlugin, Circ} from 'gsap/all';

// window.TweenMax = TweenMax;
// window.Power2 = Power2;
// window.TimelineLite = TimelineLite;
// window.TimelineMax = TimelineMax;
// window.CSSRulePlugin = CSSRulePlugin;
// window.Circ = Circ;

// import LazyLoad from 'vanilla-lazyload';

//sweetAlert
import Swal from 'sweetalert2';

import * as moment from 'moment';

// console.log('moment', moment);
window.moment = moment;
require('tempusdominus-bootstrap-4/build/js/tempusdominus-bootstrap-4.js')

// console.log('te<mpusdominus', )

// window.jQuery.prototype.datetimepicker = window.$.prototype.datetimepicker = tempusdominus;

// Support promise
// import 'promise-polyfill/src/polyfill';

/* DATATABLES */
var DataTable = require('datatables.net');

require('datatables.net-src/js/integration/dataTables.bootstrap4');

require('datatables.net-buttons')(window, $);

require('select2');
window.Dropzone = require('dropzone');
Dropzone.autoDiscover = false;

// window.lazyLoadInstance = new LazyLoad({
//     elements_selector: "img"
//     // ... more custom settings?
// });

/* SCROLL REVEAL */
// window.ScrollReveal = require('scrollreveal').default;

//CLEAVE
// require('cleave.js/dist/cleave');

//LEAFLET
// require('leaflet');

/* OWL CAROUSEL FOR SLIDER */
// require('owl.carousel');


/* LAVALAMP FOR SWITCH */
// require('lavalamp');

/* magnify */
// require('magnify');

require('../utils/datatables/buttons.server-side');

import Helpers from '../utils/_helpers';
window.Helpers = Helpers;

require('../utils/_global');

require('./partials/_sidebar');

// jQuery('.js-popover-videos').popover({
//     html: true,
//     satinize: false,
//     placement: 'bottom',
//     boundary: 'window'
// })

var myDefaultWhiteList = jQuery.fn.tooltip.Constructor.Default.whiteList;

myDefaultWhiteList.form = ['action', 'method'];
myDefaultWhiteList.input = ['type', 'name', 'value'];
myDefaultWhiteList.button = ['type', 'class', 'onclick'];

jQuery('.js-popover-videos').popover({
    html: true,
    satinize: false,
    placement: 'bottom',
    boundary: 'window'
});

jQuery('body').popover({
    selector: '.js-popover-dashboard-videos',
    html: true,
    satinize: false,
    placement: 'left',
    boundary: 'window'
});

jQuery(function () {
    $('[data-toggle="tooltip"]').tooltip()
})

jQuery('.row-fiche [data-toggle="tooltip"]').tooltip();

jQuery('.js-dossier').on('click', function(e) {
    e.preventDefault();
    $($(this).data('target')).collapse('toggle');
    
    if($(this).hasClass('collapsed')) {
        $(this).removeClass('collapsed');
        $(this).attr({'aria-expanded': true, 'data-toggle': ''});
    }
    else {
        $(this).addClass('collapsed');
        $(this).removeAttr('data-toggle')
        $(this).attr({'aria-expanded': false});
    }

})

jQuery('.js-popover-videos').on('click', function(e) {
    e.preventDefault();
    var haspopover = $(this).attr('aria-describedby');

    // console.log('haspopover', haspopover)
    
    // console.log($(this).parent().parent())
    if($(this).parent().parent().hasClass('js-dossier')) {
        e.stopPropagation();
        // $(this).popover('show')
    }
    // else {

    // }
})

jQuery('.js-popover-videos').on('show.bs.popover', function () {
    jQuery('.js-popover-videos').not($(this)).popover('hide');
    // do something...
  })
jQuery('.scrollable').on('scroll', function() {
    jQuery('.js-popover-videos').popover('hide');
})

jQuery('.card-for-custom-dt .collapse').on('show.bs.collapse', function () {
    // do something...
    jQuery('.js-popover-videos').popover('hide');
})
